import React from "react"

import AuthContextProvider from "./src/context/AuthContext"

import LoadingContextProvider from "./src/context/LoadingContext"
import BreadcrumbsContextProvider from "./src/context/BreadcrumbsContext"
import MenuContextProvider from "./src/context/MenuContext"



export const wrapRootElement = ({ element }) => (
  <BreadcrumbsContextProvider>
    <LoadingContextProvider>
      <MenuContextProvider>
        <AuthContextProvider>

          {element}

        </AuthContextProvider>
      </MenuContextProvider>
    </LoadingContextProvider>
  </BreadcrumbsContextProvider>

)